import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Spin from '../views/Spin.vue'
import Login from '../views/Login.vue'
import Promotion from '../views/Promotion.vue'
import Home from '../views/Home.vue'
import Play from '../views/Play.vue'
import PlayGame from '../views/PlayGame.vue'
import Gift from '../views/Gift.vue'
import Boxs from '../views/Boxs.vue'
import Register from '../views/Register.vue'
import History from '../views/History.vue'
import Roulet from '../views/Roulet.vue'
import Partner from '../views/Partner.vue'
import Continue from '../views/Continue.vue'
import Cashback from '../views/Cashback.vue'
import Huay from '../views/Huay.vue'
import Rewards from '../views/Rewards.vue'
import Gashapon from '../views/Gashapon.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
    meta: {
      auth: true
    }
  },
  {
    path: '/playgame/:key',
    name: 'Playgame',
    component: PlayGame,
    meta: {
      auth: true
    }
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: Promotion,
    meta: {
      auth: true
    }
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    meta: {
      auth: true
    }
  },
  {
    path: '/cashback',
    name: 'cashback',
    component: Cashback,
    meta: {
      auth: true
    }
  },
  {
    path: '/continue',
    name: 'continue',
    component: Continue,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/gift',
    name: 'gift',
    component: Gift
  },
  {
    path: '/boxs',
    name: 'boxs',
    component: Boxs
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/spin',
    name: 'spin',
    component: Spin,
    meta: {
      auth: true
    }
  },
  {
    path: '/huay',
    name: 'huay',
    component: Huay,
    meta: {
      auth: true
    }
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
      auth: true
    }
  },
  {
    path: '/roulet',
    name: 'roulet',
    component: Roulet,
    meta: {
      auth: true
    }
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: Rewards,
    meta: {
      auth: true
    }
  },
  {
    path: '/gashapon',
    name: 'gashapon',
    component: Gashapon,
    meta: {
      auth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!firebase.auth().currentUser) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
